import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import s from './EmbeddedVideo.module.css';

import {
    EmbeddedVideoProps,
    autoPlayProps,
} from '../../types/components/video';

const EmbeddedVideo = ({
    src,
    autoPlay,
    controls,
    muted,
}: EmbeddedVideoProps) => {
    const [hasWindow, setHasWindow] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setHasWindow(true);
        }
    }, []);

    if (!hasWindow) {
        // Returns null on first render, so the client and server match
        return null;
    }

    let autoPlayAttr: autoPlayProps = {
        muted: muted,
    };

    if (autoPlay === true) {
        autoPlayAttr = {
            playing: true,
            loop: true,
            muted: true,
            rel: 0,
        };
    }

    return (
        <div className={s.EmbeddedVideo} data-testid="embedded-video">
            {src && hasWindow ? (
                <ReactPlayer
                    url={src}
                    width="100%"
                    height="100%"
                    controls={controls}
                    {...autoPlayAttr}
                />
            ) : null}
        </div>
    );
};

export default EmbeddedVideo;
